import React from "react";
import theme from "theme";
import { Theme, Text, Em, Strong, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Гольф-клуб Вітер
			</title>
			<meta name={"description"} content={"Вирушайте в першокласну подорож у гольф у Гольф-клуб Вітер, де традиції поєднуються з сучасною розкішшю серед природи."} />
			<meta property={"og:title"} content={"Гольф-клуб Вітер"} />
			<meta property={"og:description"} content={"Вирушайте в першокласну подорож у гольф у Гольф-клуб Вітер, де традиції поєднуються з сучасною розкішшю серед природи."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12:57:14.342Z"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1587174486073-ae5e5cff23aa?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link5">
				contact@greenvista.gmail
			</Override>
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Спектр послуг
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					<Strong>
						<Em>
							Наші першокласні гольф-клуби чекають на вас
						</Em>
					</Strong>
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					У Гольф-клуб Вітер ми прагнемо запропонувати всеосяжний досвід гри в гольф, який задовольняє всі ваші потреби. Незалежно від того, чи хочете ви покращити свою гру, відпочити в розкішній обстановці або спілкуватися з колегами-любителями гольфу, наш клуб надає низку послуг, розроблених для покращення кожного аспекту вашого життя в гольфі.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12:57:14.324Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/1-2.jpg?v=2024-06-04T12%3A57%3A14.324Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12:57:14.342Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						srcSet="https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665f0c1e8e2e8e00217c6d90/images/2-2.jpg?v=2024-06-04T12%3A57%3A14.342Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 2rem 0px"
				font="normal 600 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Наші основні послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--base">
						Складні макети: наші курси створені, щоб випробувати та розвинути ваші навички, показуючи різноманітні дизайни отворів, які підходять для всіх рівнів гри.
						<br />
						<br />
						{"\n "}Фірмові лунки: на кожному полі є фірмові лунки, які настільки ж красиві, як і складні, створюючи незабутні моменти гри в гольф.{"\n"}
						<br />
						<br />
						{" "}Технічне обслуговування поля: Первинні умови поля підтримуються цілий рік, забезпечуючи ідеальне ігрове покриття щодня.
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Поля для гольфу
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--base">
						Комплексні тренувальні зони: вдосконалюйте свій замах і покращуйте свою гру за допомогою наших тренувальних засобів найвищого рівня.{"\n"}
						<br />
						<br />
						{" "}Полігон для тренування: оснащений покажчиками відстані та різноманітним рельєфом, наш полігон ідеально підходить для відточування дальніх ударів.
						<br />
						<br />
						{"\n "}Паттінг-Грін: кілька паттінг-грінів дозволяють тренуватися на різних схилах і швидкостях, віддзеркалюючи умови, які ви знайдете на наших курсах.
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="left">
						Найсучасніші практичні приміщення
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--base">
						Повністю укомплектований асортимент: наш професійний магазин укомплектований найновішим одягом та обладнанням для гольфу, включаючи провідні бренди та ексклюзивні товари.{"\n"}
						<br />
						<br />
						{" "}Індивідуальна комплектація: отримайте ключки, виготовлені на замовлення нашими спеціалістами, щоб ваше спорядження відповідало вашому стилю гри.
						<br />
						<br />
						{"\n "}Порада щодо спорядження: наш досвідчений персонал завжди готовий порадити та допомогти вибрати найкращі продукти для вашої гри.
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Pro Shop
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--base">
						Відпочинок і спілкування: наш клубний будинок – це місце відпочинку та спілкування, де ви можете розслабитися або поспілкуватися з однодумцями.
						<br />
						<br />
						{"\n "}Харчування та бар: насолоджуйтесь вишуканими стравами та освіжаючими напоями в нашому клубному ресторані та барі.{"\n"}
						<br />
						<br />
						{" "}Проведення заходів: клубний будинок доступний для приватних заходів, від турнірів з гольфу до святкових банкетів.
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Розкішний клубний будинок
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--base">
						Захоплююча спільнота: беріть участь у нашій яскравій спільноті за допомогою ексклюзивних заходів лише для учасників.{"\n"}
						<br />
						<br />
						{" "}Турніри та змагання: Регулярно заплановані змагання обслуговують усіх рівнів навичок і дають можливість випробувати свої сили.{"\n"}
						<br />
						<br />
						{" "}Світські зустрічі: наш календар включає часті світські заходи, від тематичних вечерь до випадкових зустрічей.
					</Text>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="left">
						Ексклюзивні події для учасників
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="left">
						<Em>
							Дізнайтеся більше в Гольф-клуб Вітер
						</Em>
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						<Em font="--base">
							І це лише короткий огляд того, що може запропонувати гольф-клуб Гольф-клуб Вітер. У нашому клубі є ще багато чого для вивчення та досвіду. Ми запрошуємо вас відвідати або зв’язатися з нами для більш детального ознайомлення з нашим повним набором послуг і переконатися з перших вуст, чому Гольф-клуб Вітерє місцем вибору для вибагливих ентузіастів гольфу.
						</Em>
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-orange"
				font="normal 400 17px/1.5 --fontFamily-sans"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Зв'яжіться з нами для отримання додаткової інформації
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});